<template>
  <div class="sheet">
    <div class="sheet-title text-center">インフルエンザ予防接種 予診票</div>
    <div class="text-right" style="margin-top: -24px">任意接種用</div>
    <table :style="tableStyle">
      <tr>
        <th class="question" style="width: 40mm; height: 10mm">
          (予防接種を受ける人の)氏名
        </th>
        <td class="answer" style="min-width: 80mm">{{ answerData.name }}</td>
        <th class="question" style="width: 30mm">診察前体温</th>
        <td class="answer thick-border" style="width: 40mm">
          <br />
          <p style="text-align: right; margin: 0">_________度________分</p>
        </td>
      </tr>
      <tr style="height: 10mm">
        <th class="question">住所</th>
        <td class="answer">{{ answerData.address }}</td>
        <th class="question">生年月日</th>
        <td class="answer" v-if="answerData.birthdate">
          {{ answerData.birthdate }}
        </td>
        <td class="answer" v-else>19<br />20_____年_____月_____日</td>
      </tr>
    </table>
    <table :style="tableStyle" class="margin5">
      <tr>
        <th rowspan="2" style="width: 90mm">質問事項</th>
        <th colspan="2">回答</th>
        <th rowspan="2" style="width: 20mm">補助質問</th>
        <th rowspan="2">回答</th>
        <th rowspan="2" style="width: 35mm">医師記入欄</th>
      </tr>
      <tr>
        <td>はい</td>
        <td>いいえ</td>
      </tr>
      <tr
        v-for="(question, questionI) in answerData.restAnswers"
        :key="questionI"
      >
        <th class="cell question">{{ questionI + 1 }}.{{ question.text }}</th>
        <td style="width: 12mm; text-align: center" class="answer">
          <v-icon v-if="question.tf" small>mdi-check</v-icon>
        </td>
        <td style="width: 12mm; text-align: center" class="answer">
          <v-icon v-if="!empty && !question.tf" small>mdi-check</v-icon>
        </td>
        <td class="question">{{ question.memo }}</td>
        <td class="answer">{{ question.memoAnswer }}</td>
        <td class="thick-border"></td>
      </tr>
    </table>
    <table :style="tableStyle" class="margin5">
      <tr>
        <td style="vertical-align: top">
          医師の診察、説明を受け、予防接種の効果や副反応などについて理解した上で、接種を希望しますか?<br />
          <p style="text-align: center; margin: 0">
            <span class="answer">(接種を希望します / 接種を希望しません)</span>
          </p>
          <span class="serif">本人の署名(もしくは保護者の署名)</span
          ><br /><br />
          <p class="signature">______________________________________</p>
        </td>
        <td style="width: 105mm" class="thick-border">
          医師の記入欄:以上の問診及び診察の結果、今日の予防接種は<br />
          <p style="text-align: center; margin: 0">
            <span class="answer">( 可能 /見合わせる )</span>
          </p>
          <!-- 本人(もしくは保護者)に対して、予防接種の効果、副反応及び医薬品医療機器総合機構法に基づく救済について説明した。<br /> -->
          <span class="serif">医師の署名または記名押印</span><br />
          <br />
          <p class="signature">______________________________________</p>
        </td>
      </tr>
    </table>
    <table :style="tableStyle" class="margin5">
      <tr>
        <th>使用ワクチン名・メーカー名</th>
        <th>接種量</th>
        <th>実施場所・医師名・接種日時</th>
      </tr>
      <tr>
        <td style="width: 50mm">
          使用したワクチンのシールを貼ってください。<br /><br /><br /><br />
        </td>
        <td>
          (皮下接種) <br />□0.25 mL (6ヶ月以上3歳未満)<br />
          □0.5 mL (3歳以上)<br />
        </td>
        <td style="width: 100mm">
          実施場所<br /><br />医師名<br /><br />
          <template v-if="empty">
            接種日時 20________年________月________日________時________分
          </template>
          <template v-else>
            接種日時 西暦 {{ new Date().getFullYear() }}年
            {{ new Date().getMonth() + 1 }}月 {{ new Date().getDate() }}日
            {{ new Date().getHours() }}時 {{ new Date().getMinutes() }}分
          </template>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: { answerData: Object },
  methods: {},
  data() {
    return {
      fontSize: 2.8,
      empty: false,
    };
  },
  computed: {
    tableStyle() {
      return {
        "font-size": `${this.fontSize}mm`,
      };
    },
  },
  mounted() {
    this.empty = this.answerData.empty || false;
  },
};
</script>
<style scoped>
@media print {
  .container {
    padding: 0 !important;
  }
}
@page {
  size: A4;
  margin: 0;
}
.sheet {
  margin: 0mm;
  padding: 10mm;
  position: relative;
  width: 210mm;
  height: 294mm;
}
.sheet-title {
  /* padding-top: 10mm; */
  font-size: 9mm;
}
.cell {
  padding: 4px;
}
.question {
  font-weight: normal;
}
.answer {
  font-weight: bold;
  font-size: 3.2mm;
}
.alert {
  font-weight: bold;
  font-family: "serif";
  text-align: right;
  font-style: italic;
  text-decoration: underline wavy 2px black;
  margin: 0px;
}
.signature {
  text-align: right;
  margin-bottom: 0;
}
.serif {
  font-weight: bold;
  font-size: 3.2mm;
  font-family: "serif";
}
.margin5 {
  margin-top: 5px;
}
.thick-border {
  border: solid 3px black;
}
table {
  border: solid 1px black;
  border-collapse: collapse;
}
td {
  border: solid 1px black;
  padding: 2px;
}
th {
  border: solid 1px black;
  text-align: left;
  padding: 2px;
}
</style>
