<template>
  <div class="receipt-wrapper">
    <div class="receipt-sheet">
      <v-row no-gutters>
        <v-col cols="2" padding="0">
          {{ issuedDate }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="text-center green lighten-4 receipt-title">領収書</div>
          <div class="box1">
            <p class="title font-weight-medium">
              {{ answerData.companyName }}<br />
              {{ answerData.name }} 様
            </p>
          </div>
          <div class="box2">
            <h2 class="display-1">
              <span class="title mr-12">金額（消費税込）</span>
              <span class="ml-12">¥{{ amount }}-</span>
            </h2>
          </div>
          <div class="box3">
            <h4 class="title">
              <span class="mr-12">但し</span>{{ description }}として
            </h4>
          </div>
          <div class="text-right box4">
            <p>
              医療法人社団撫子
              <br />高松メディカルクリニック
            </p>
            <p>
              東京都三鷹市下連雀4-16-12
              <br />三鷹第一ビル2F
            </p>
          </div>
        </v-col>
      </v-row>
      <img class="kaku-in" src="/img/takamatsu.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // printName: String,
    // companyName: String,
    answerData: Object,
    amount: Number,
    description: String,
  },
  computed: {
    issuedDate() {
      return new Date().toISOString().slice(0, 10);
    },
  },
  mounted() {
    this.$emit("mounted");
  },
};
</script>
<style scoped>
@media print {
  .container {
    padding: 0 !important;
  }
}
@page {
  size: A4;
  margin: 0;
}
.receipt-wrapper {
  margin-top: 27mm;
  margin-left: 20mm;
  height: 240mm;
  margin-bottom: 27mm;
}
.receipt-sheet {
  width: 170mm;
  height: 120mm;
  position: relative;
}
.receipt-sheet:first-child {
  border-bottom: dotted 1px black;
}
.receipt-title {
  font-size: 2rem;
}
.box1 {
  margin-top: 20px;
  margin-right: 60px;
  margin-left: 60px;
}
.box2 {
  margin-right: 60px;
  margin-left: 60px;
  border-bottom: solid 1.5px;
}
.box3 {
  margin-top: 10px;
  margin-right: 60px;
  margin-left: 100px;
  border-bottom: solid 1.5px;
}
.box4 {
  margin-top: 40px;
  margin-right: 60px;
}
.kaku-in {
  width: 30mm;
  height: 30mm;
  position: absolute;
  bottom: 0mm;
  left: 140mm;
  z-index: 10;
}
</style>
