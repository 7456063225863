<template>
  <div>
    <div class="d-print-none">
      <h2 class="page-title pt-5 mb-8">領収書・予診票印刷</h2>
      <p v-if="qrread">QRコードをカメラに読み込ませてください。</p>
      <p v-else>QRコードをリーダーで読み込んでください。</p>
      <qrcode-stream
        :camera="camera"
        v-if="qrread"
        @decode="onDecode"
        @init="onInit"
        style="max-width: 800px; margin: auto"
      ></qrcode-stream>
      <v-row v-if="!qrread">
        <v-col cols="10">
          <v-text-field
            ref="codeInput"
            v-model="qrText"
            @keydown.enter="onDecode(qrText)"
          />
        </v-col>
        <v-col cols="2">
          <v-btn @click="onDecode(qrText)" dark>読み込み</v-btn>
        </v-col>
      </v-row>
      <v-switch label="カメラ" v-model="qrread" />
      <v-btn @click="refreshCamera"
        ><v-icon>mdi-refresh</v-icon>カメラ再起動</v-btn
      >
      <v-select label="カメラ" v-model="camera" :items="cameraOptions" />
      <v-snackbar v-model="snackbar.show" top>{{ snackbar.text }}</v-snackbar>

      <h2 class="pt-5 mb-8">・白紙の予診票・領収書印刷</h2>
      <v-row class="ml-6">
        <v-col>
          <v-select label="予診票選択" :items="mhIds" v-model="currentId" />
        </v-col>
        <v-col>
          <v-text-field v-model.number="printNum" label="部数" type="number" />
        </v-col>
        <v-col>
          <v-btn @click="emptyPrint"
            ><v-icon>mdi-printer</v-icon>白紙の予診票印刷</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div v-if="printShow" class="only-print">
      <div v-for="printI in printNum" :key="printI">
        <receipt
          v-if="printReceipt"
          :answerData="answerData"
          :key="gen * 2"
          :amount="amount"
          :description="description"
        />
        <medical-history-print :answerData="answerData" :key="gen * 2 + 1" />
      </div>
    </div>
    <!-- 事前に画像をロードするために -->
    <img class="nodisplay" src="/img/takamatsu.png" />
    <v-dialog v-model="dialog.show">
      <v-card>
        <v-card-title>{{ dialog.title }}</v-card-title>
        <v-card-text>{{ dialog.text }}</v-card-text>
        <v-card-actions
          ><v-btn @click="dialog.show = false">OK</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/firestore";
import { QrcodeStream } from "vue-qrcode-reader";
import Receipt from "@/components/receipt.vue";
import MedicalHistoryPrint from "@/components/medicalHistoryPrint.vue";
import Mixin from "@/assets/js/utils";
import Datastore from "nedb";

export default {
  components: { QrcodeStream, Receipt, MedicalHistoryPrint },
  data() {
    return {
      firestoreData: {},
      nedb: {},
      camera: "auto",
      cameraOptions: [
        { text: "自動", value: "auto" },
        { text: "後ろ", value: "rear" },
        { text: "前", value: "front" },
      ],
      printShow: false,
      qrread: true,
      dialog: { show: false, text: "", title: "" },
      qrText: "",
      answerData: {},
      mhIds: [],
      printNum: 1,
      printReceipt: false,
      //   companyName: "東京メディカルサービス",
      //   printName: "",
      amount: "",
      description: "",
      drawn: { mhp: false, receipt: false },
      snackbar: { show: false, text: "" },
      gen: 0,
      currentId: "",
    };
  },
  mixins: [Mixin],
  mounted() {
    this.loadFireStore();
  },
  created() {
    this.nedb = new Datastore({
      filename: "printData.db",
    });
    this.nedb.loadDatabase();
  },
  methods: {
    emptyPrint() {
      this.onDecode(`${this.currentId}?empty`);
    },
    refreshCamera() {
      this.qrread = false;
      this.$nextTick(() => {
        this.qrread = true;
      });
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    async onDecode(decodedString) {
      this.qrString = "";
      if (decodedString === "") {
        return;
      }
      this.snackbar = {
        show: true,
        text: "領収書と予診票の印刷処理をしています。",
      };
      this.printShow = false;
      this.gen += 1;
      const qrstArr = decodedString.split("?");
      const examId = qrstArr[0];
      if (this.firestoreData[examId] === undefined) {
        this.dialog = {
          show: true,
          text: `QRコードのID(${examId})はデータベースに見つかりませんでした。`,
          title: "IDエラー",
        };
      } else {
        const currentConfig = this.firestoreData[examId];
        this.answerData = this.decodeQRcode(
          decodedString,
          currentConfig.predictionData[0].questions
        );
        if (!this.answerData.empty) {
          this.printNum = 1;
        }
        this.printReceipt = currentConfig.printReceipt;
        this.amount = Number(currentConfig.printConfig.amount) || "";
        this.description = currentConfig.printConfig.description;
        this.storeNedb();
        this.printShow = true;
        this.$nextTick(() => {
          window.print();
        });
      }
    },
    loadFireStore() {
      const mhIds = [];
      firebase
        .firestore()
        .collection("exam_data")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.firestoreData[doc.id] = doc.data();
            mhIds.push({ value: doc.id, text: doc.data().examName });
          });
          this.mhIds = mhIds;
        });
    },
    storeNedb() {
      const storeAnswer = {};
      const storeQuestions = [];
      storeAnswer.created = new Date();
      storeAnswer.examId = this.answerData.examId;
      storeAnswer.uid = this.answerData.uid;
      this.$store.state.defaultQuestions.forEach((dq) => {
        storeAnswer[dq.value] = this.answerData[dq.value];
        storeQuestions.push(dq.value);
      });
      storeAnswer.restAnswers = [];
      this.answerData.restAnswers.forEach((rq) => {
        const answerAdd = rq.tf + rq.memoAnswer;
        const questionAdd = {
          text: rq.text,
          memo: rq.memo,
        };
        storeAnswer.restAnswers.push(answerAdd);
        storeQuestions.push(questionAdd);
      });
      const updateAnswer = {
        $push: { data: storeAnswer },
      };
      const updateQuestions = {
        $set: { questions: storeQuestions },
      };
      this.nedb.update({ examId: this.answerData.examId }, updateAnswer, {
        upsert: true,
      });
      this.nedb.update({ examId: this.answerData.examId }, updateQuestions, {
        upsert: true,
      });
    },
  },
  watch: {
    qrread(val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs.codeInput.focus();
        });
      }
    },
  },
};
</script>

<style>
.nodisplay {
  display: none;
}
@media print {
  .only-print {
    display: block;
  }
  .container {
    padding: 0 !important;
  }
  .v-main {
    padding: 0 !important;
  }
}
@media screen {
  .only-print {
    display: none;
  }
}
.page-title {
  border-bottom: solid 3px rgba(0, 0, 0, 0.87);
}
</style>
